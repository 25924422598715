import React, { useState, useEffect, useRef } from 'react'
import { DataTable } from 'primereact/datatable'
import { classNames } from 'primereact/utils'
import { Column } from 'primereact/column'
import { Dropdown } from 'primereact/dropdown'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { Toast } from 'primereact/toast'
import { Toolbar } from 'primereact/toolbar'
import { InputText } from 'primereact/inputtext'
import { Calendar } from 'primereact/calendar'
import { FilterMatchMode } from 'primereact/api'
import { alphabeticalSortByKey } from '../../../Shared/sortHelper'
import TaskService from '../../../Services/TaskService'
import GroupService from '../../../Services/GroupService'
import LocaleService from '../../../Services/LocaleService'
import CategoryService from '../../../Services/CategoryService'
import WorkflowService from '../../../Services/WorkflowService'
import AddManyTasksModal from './AddManyTasksModal'
import { validateFieldsStartChar } from '../../../Shared/validators'
import Papa from 'papaparse';
import moment from 'moment';
import timezones from '../../../Shared/timezones'
export default function ItemTable(props) {
    let emptyItem = {
        _id: null,
        taskName: null,
        groupUuid: null,
        workflowUuid: null,
        localeUuid: null,
        categoryUuid: null,
        onStepUuid: null,
        isCurrentStepComplete: false,
        targetDate: null,
        projectUuid: props.project.projectUuid
    }
    const fileUpload = useRef(null)
    const [csvData, setCsvData] = useState(null)
    const [loading, setLoading] = useState(true)
    const [items, setItems] = useState([])
    const [item, setItem] = useState(emptyItem)
    const [error, setError] = useState(null)
    const [itemDialog, setItemDialog] = useState(false)
    const [deleteItemDialog, setDeleteItemDialog] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const toast = useRef(null)
    const [projectPermissions, setProjectPermissions] = useState({})
    const [filters] = useState({
        taskName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        workflowName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        groupName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        localeName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        categoryName: { value: null, matchMode: FilterMatchMode.CONTAINS }
    })
    const [renderManyNewTasksModal, setRenderManyNewTasksModal] = useState(false)
    const [renderUploadDueDatesModal, setRenderUploadDueDatesModel] = useState(false)
    const [workflows, setWorkflows] = useState(null)
    const [groups, setGroups] = useState(null)
    const [locales, setLocales] = useState(null)
    const [categories, setCategories] = useState(null)
    const [selectedTimezone, setSelectedTimezone] = useState(0);

    const [selectedWorkflow, setSelectedWorkflow] = useState(null);
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [selectedLocale, setSelectedLocale] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);

    async function fetchItems() {
        setLoading(true)
        try {
            const response = await TaskService.find({ projectUuid: props.project.projectUuid })
            const results = response.data.map(task => {
                return ({
                    ...task,
                    workflowName: workflows.find((workflow) => workflow.workflowUuid === task.workflowUuid).workflowName,
                    groupName: groups.find((group) => group.groupUuid === task.groupUuid).groupName,
                    localeName: locales.find((locale) => locale.localeUuid === task.localeUuid)?.localeName,
                    categoryName: categories.find((category) => category.categoryUuid === task.categoryUuid)?.categoryName
                })
            }
            )

            setItems(results)
        } catch (err) {
            console.error(err)
            setError(err?.response?.data || 'Error')
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (workflows && groups && locales && categories) {
            fetchItems()
        }
    }, [workflows, groups, locales, categories])

    useEffect(() => {
        setProjectPermissions(props.projectPermissions)
        try {
            WorkflowService.find({ projectUuid: props.project.projectUuid }).then(response => setWorkflows(response.data))
            GroupService.find({ projectUuid: props.project.projectUuid }).then(response => setGroups(response.data))
            LocaleService.find({ projectUuid: props.project.projectUuid }).then(response => setLocales(response.data))
            CategoryService.find({ projectUuid: props.project.projectUuid }).then(response => setCategories(response.data))
        } catch (err) {
            console.error(err)
            setError(err?.response?.data || 'Error')
        }
    }, [props.project])

    const editItem = (item) => {
        setItem({ ...item })
        setItemDialog(true)
    }

    const confirmDeleteItem = (item) => {
        setItem(item)
        setDeleteItemDialog(true)
    }
    const hideDeleteItemDialog = () => {
        setDeleteItemDialog(false)
    }

    const removedSelectedData = () => {
        setSelectedWorkflow(null)
        setSelectedGroup(null)
        setSelectedLocale(null)
        setSelectedCategory(null)
    }

    const hideItemDialog = () => {
        removedSelectedData()
        setSubmitted(false)
        setItemDialog(false)
    }

    const saveItem = async () => {
        setSubmitted(true)
        const result = validateFieldsStartChar(item)
        if (!result.isValid) {
            toast.current.show({ severity: 'error', summary: 'Invalid value', detail: `The value of ${result.key} cannot starts with =, +, - or @: ${result.value}`, life: 5000 })
            setSubmitted(false)
            return
        }
        item.workflowUuid = selectedWorkflow?.workflowUuid || item.workflowUuid
        item.groupUuid = selectedGroup?.groupUuid || item.groupUuid
        item.localeUuid = selectedLocale?.localeUuid || item.localeUuid
        item.categoryUuid = selectedCategory?.categoryUuid || item.categoryUuid
        item.onStepUuid = item?.onStepUuid || selectedWorkflow?.assignedSteps[0]
        delete item.workflowName
        delete item.groupName
        delete item.localeName
        delete item.categoryName

        try {
            if (item.taskUuid) {
                await TaskService.update(item.taskUuid, item).then(data => fetchItems())
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Updated', life: 3000 })
            } else {
                await TaskService.create(item).then(data => fetchItems())
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Created', life: 3000 })
            }
            setItemDialog(false)
            setItem(emptyItem)
            removedSelectedData()
        } catch (err) {
            console.error(err)
            toast.current.show({ severity: 'error', summary: 'Error', detail: err.response.data, life: 5000 })
        }
    }

    const deleteItem = async () => {
        try {
            await TaskService.delete(item.taskUuid).then(data => fetchItems())
            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Deleted', life: 3000 })
            setDeleteItemDialog(false)
            setItem(emptyItem)
        } catch (err) {
            console.error(err)
            toast.current.show({ severity: 'error', summary: 'Error', detail: err.response.data, life: 5000 })
        }

    }
    const itemDialogFooter = (
        <React.Fragment>
            <Button label="Cancel" icon="pi pi-times" outlined onClick={hideItemDialog} />
            <Button label="Save" icon="pi pi-check" disabled={item.taskName?.length ? false : true} onClick={saveItem} />
        </React.Fragment>
    )

    const deleteItemDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" outlined onClick={hideDeleteItemDialog} />
            <Button label="Yes" icon="pi pi-check" severity="danger" onClick={deleteItem} />
        </React.Fragment>
    )
    const handleUploadDueDatesCSV = async () => {
        if (!csvData) return
        const convertedTimeZoneDates = csvData.map(data => {
            const localDate = moment(data.taskDueDate).startOf('day');
            const utcDate = localDate.subtract(selectedTimezone ?? 0, 'hours');
            return {
                taskUuid: data.taskUuid,
                taskDueDate: utcDate.format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z',
            }
        })
        try {
            await TaskService.uploadTasksDueDates({ csvData: convertedTimeZoneDates, projectUuid: props.project.projectUuid })
            fetchItems()
        } catch (e) {
            console.error(e)
            toast.current.show({ severity: 'error', summary: 'Something went wrong while uploading tasks due dates', life: 5000 });
        } finally {
            setRenderUploadDueDatesModel(false)
        }
    }
    const uploadDueDatesDialogFooter = (
        <React.Fragment>
            <Button label="Cancel" icon="pi pi-times" outlined onClick={() => { setRenderUploadDueDatesModel(false) }} />
            <Button label="Upload" icon="pi pi-check" severity="danger" onClick={handleUploadDueDatesCSV} />
        </React.Fragment>
    )

    const handleExportDueDatesTemplate = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const link = document.createElement('a');
        const exportData = items.map((item) => {
            return {
                'Task name': item.taskName,
                'Locale': item.localeName,
                'Workflow': item.workflowName,
                'Group': item.groupName,
                'Task due date': item.taskDueDate ? `'${moment(item.taskDueDate).format('YYYY-MM-DD')}` : '',
                taskUuid: item.taskUuid,
            };
        });
        const csvData = Papa.unparse(exportData);
        link.href = URL.createObjectURL(new Blob([csvData], { type: 'text/csv' }));
        link.download = 'tasks.csv';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const handleFileChanged = () => {
        if (fileUpload.current) {
            try {
                Papa.parse(fileUpload.current.files[0], {
                    header: true,
                    skipEmptyLines: true,
                    complete: (results) => {
                        let uploadedTaskDueDates = []
                        for (let i = 0; i < results.data.length; i++) {
                            const parsedMoment = moment(results.data[i]['Task due date']);
                            if (results.data[i]['Task due date'] !== '' && !parsedMoment.isValid()) {
                                toast.current.show({ severity: 'error', summary: 'Invalid date format', detail: `${results.data[i]['Task due date']} is not support, please provide task due date field in the csv file as format ['YYYY-MM-DD] in Excel to avoid auto-format-convertion of Excel.`, life: 5000 });
                                fileUpload.current.value = null;
                                return
                            }
                            const result = validateFieldsStartChar(results.data[i])
                            if (!result.isValid) {
                                toast.current.show({ severity: 'error', summary: 'Invalid first character', detail: `The value of ${result.key} cannot starts with =, +, - or @: ${result.value}`, life: 5000 });
                                fileUpload.current.value = null;
                                return
                            }
                        }
                        results.data.forEach(columnData => {
                            if (columnData['Task due date'] !== '') {
                                uploadedTaskDueDates.push({
                                    taskUuid: columnData.taskUuid,
                                    taskDueDate: columnData['Task due date']
                                });
                            }
                        });
                        setCsvData(uploadedTaskDueDates)
                    }
                });
            } catch (e) {
                console.error(e)
            }

        }
    }


    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || ''
        let _item = { ...item }

        _item[`${name}`] = val

        setItem(_item)
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <>
                    {projectPermissions?.canUpdateTasks &&
                        <Button icon="pi pi-pencil" outlined className="mr-2 bg-primary right-0" onClick={() => editItem(rowData)} />}
                    {projectPermissions?.canDeleteTasks &&
                        <Button icon="pi pi-trash" outlined severity="danger bg-primary right-0" onClick={() => confirmDeleteItem(rowData)} />}
                </>
            </React.Fragment>
        )
    }

    const openNew = () => {
        setItem(emptyItem)
        setSubmitted(false)
        setItemDialog(true)
    }

    const leftToolbarTemplate = () => {
        return (
            <div className="flex">
                {projectPermissions?.canCreateTasks &&
                    <Button label="New" icon="pi pi-plus" severity="success" onClick={openNew} />
                }
            </div>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <div className="flex gap-2">
                <Button label="Upload due dates" icon="pi pi-upload" severity="success" onClick={() => setRenderUploadDueDatesModel(true)} />
                {projectPermissions?.canCreateTasks &&
                    <Button label="Import" icon="pi pi-upload" severity="success" onClick={() => setRenderManyNewTasksModal(true)} />
                }
            </div>
        )
    }

    const workflowNameRowFilterTemplate = (options) => {

        return (
            <Dropdown value={options.value} options={workflows} onChange={(e) => options.filterApplyCallback(e.value)} optionLabel="workflowName" optionValue="workflowName" placeholder="Select One" filter className="p-column-filter" />
        )
    }

    const groupNameRowFilterTemplate = (options) => {
        return (
            <Dropdown value={options.value} options={groups} onChange={(e) => options.filterApplyCallback(e.value)} optionLabel="groupName" optionValue="groupName" placeholder="Select One" filter className="p-column-filter" />
        )
    }

    const localeNameRowFilterTemplate = (options) => {
        return (
            <Dropdown value={options.value} options={locales} onChange={(e) => options.filterApplyCallback(e.value)} optionLabel="localeName" optionValue="localeName" placeholder="Select One" filter className="p-column-filter" />
        )
    }

    const categoryNameRowFilterTemplate = (options) => {
        return (
            <Dropdown value={options.value} options={categories} onChange={(e) => options.filterApplyCallback(e.value)} optionLabel="categoryName" optionValue="categoryName" placeholder="Select One" filter className="p-column-filter" />
        )
    }
    const statusBodyTemplate = (rowData) => {
        const workflow = workflows.find(workflow => workflow.workflowUuid === rowData.workflowUuid)
        const denominator = workflow.assignedSteps.length
        const numerator = workflow.assignedSteps.indexOf(rowData.onStepUuid) + 1
        const result = `step ${numerator} out of ${denominator}`
        return result
    }

    const updatedAtBodyTemplate = (rowData) => {
        const theDate = rowData?.updatedAt || rowData.dateUpdated
        const formatedDate = new Date(theDate).toLocaleString([], { year: 'numeric', month: 'numeric', day: 'numeric' })
        return formatedDate
    }

    const taskDueDateTemplate = (rowData) => {
        return rowData?.taskDueDate ? new Date(rowData?.taskDueDate).toLocaleString([], { year: 'numeric', month: 'numeric', day: 'numeric' }) : ''
    }
    return (
        <>
            <div className="card" style={{ height: "calc(100vh - 300px)" }}>
                <Toast ref={toast} />
                <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate} ></Toolbar>
                <DataTable value={items} tableStyle={{}} loading={loading}
                    scrollable scrollHeight="flex"
                    filters={filters} filterDisplay="row"
                    sortField="updatedAt" sortOrder="-1">
                    <Column field="taskName" filter showFilterMenu={false} sortable header="Name" style={{ minWidth: '12rem' }}></Column>
                    <Column field="workflowName" filter sortable header="Workflow" showFilterMenu={false} filterElement={workflowNameRowFilterTemplate}></Column>
                    <Column field="groupName" filter sortable header="Group" showFilterMenu={false} filterElement={groupNameRowFilterTemplate}></Column>
                    <Column field="localeName" filter sortable header="Locale" showFilterMenu={false} filterElement={localeNameRowFilterTemplate}></Column>
                    <Column field="categoryName" filter sortable header="Category" showFilterMenu={false} filterElement={categoryNameRowFilterTemplate}></Column>
                    <Column body={statusBodyTemplate} header="Status" style={{ minWidth: '12rem' }}></Column>
                    <Column field="updatedAt" body={updatedAtBodyTemplate} sortable header="Date Updated"></Column>
                    <Column field="taskDueDate" body={taskDueDateTemplate} sortable header="Task due date"></Column>
                    <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>
                </DataTable>
            </div>

            {renderManyNewTasksModal &&
                <AddManyTasksModal
                    groups={groups}
                    workflows={workflows}
                    fetchGroupsAndWorkflows={fetchItems}
                    setRenderManyNewTasksModal={setRenderManyNewTasksModal} />}

            <Dialog visible={deleteItemDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Confirm" modal footer={deleteItemDialogFooter} onHide={hideDeleteItemDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    {item && (
                        <span>
                            Are you sure you want to delete <b>{item.taskName}</b>?
                        </span>
                    )}
                    {error && <small className="p-error">{error}</small>}
                </div>
            </Dialog>

            <Dialog visible={itemDialog} style={{ width: '44em' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Details" modal className="p-fluid" footer={itemDialogFooter} onHide={hideItemDialog}>
                <div className="field">
                    <label htmlFor="taskName" className="font-bold">
                        Name
                    </label>
                    <InputText id="taskName" value={item.taskName} onChange={(e) => onInputChange(e, 'taskName')} required autoFocus className={classNames({ 'p-invalid': submitted && !item.taskName })} />
                    {submitted && !item.taskName && <small className="p-error">Name is required.</small>}
                </div>
                <div className="field">
                    <label htmlFor="workflowUuid" className="font-bold">
                        Workflow
                    </label>
                    <Dropdown filter value={selectedWorkflow || item.workflowName}
                        onChange={(e) => setSelectedWorkflow(e.value)}
                        options={alphabeticalSortByKey(workflows, 'workflowName')}
                        optionLabel="workflowName"
                        editable disabled={item?.stepHistory?.length}
                        placeholder="Select a Workflow" className="w-full md:w-14rem" />
                </div>
                <div className="field">
                    <label htmlFor="groupUuid" className="font-bold">
                        Group
                    </label>
                    <Dropdown filter value={selectedGroup || item.groupName} onChange={(e) => setSelectedGroup(e.value)} options={alphabeticalSortByKey(groups, 'groupName')} optionLabel="groupName"
                        editable disabled={item?.stepHistory?.length} placeholder="Select a Group" className="w-full md:w-14rem" />
                </div>
                <div className="field">
                    <label htmlFor="localeUuid" className="font-bold">
                        Locale
                    </label>
                    <Dropdown filter value={selectedLocale || item.localeName} onChange={(e) => setSelectedLocale(e.value)} options={alphabeticalSortByKey(locales, 'localeName')} optionLabel="localeName"
                        editable disabled={item?.stepHistory?.length} placeholder="Select a Loacle" className="w-full md:w-14rem" />
                </div>
                <div className="field">
                    <label htmlFor="categoryUuid" className="font-bold">
                        Category
                    </label>
                    <Dropdown filter value={selectedCategory || item.categoryName} onChange={(e) => setSelectedCategory(e.value)} options={alphabeticalSortByKey(categories, 'categoryName')} optionLabel="categoryName"
                        editable disabled={item?.stepHistory?.length} placeholder="Select a Category" className="w-full md:w-14rem" />
                </div>
                <div className="field">
                    <label htmlFor="taskDueDate" className="font-bold">
                        Task due date
                    </label>
                    <Calendar value={item?.taskDueDate ? new Date(item.taskDueDate) : null} onChange={(e) => setItem({ ...item, taskDueDate: e.value })} showIcon className="w-full md:w-14rem" />
                </div>
            </Dialog>
            <Dialog visible={renderUploadDueDatesModal} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Confirm" modal footer={uploadDueDatesDialogFooter} onHide={() => setRenderUploadDueDatesModel(false)}>
                <div>
                    <p>Select a timezone for task due date:</p>
                    <Dropdown value={selectedTimezone} style={{ width: '100%' }} optionLabel='label' options={timezones} onChange={(e) => setSelectedTimezone(e.value)} placeholder="Select a Timezone" />
                    <p>You can retrieve a <strong>template</strong> with existing tasks by exporting it.</p>
                    <Button label="Export template" icon="pi pi-upload" className="p-button-info" onClick={handleExportDueDatesTemplate} />
                    <p>Save the spreadsheet as a <strong>.csv</strong> file and upload.</p>
                    <input className='' type='file' name='TaskUploadCSV' ref={fileUpload} onChange={handleFileChanged} accept='.csv' />
                </div>
            </Dialog>
        </>

    )
}